<template>
  <div class="productPag">
    <div class="content">
      <!--      头部轮播-->
      <div class="headBanner">
        <!--    轮播-->
        <van-swipe @change="onChange" class="banner" >
          <van-swipe-item v-for="(img,index) in imgList" :key="index">
            <img :src="img"/>
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/{{ imgList.length}}</div>
          </template>
        </van-swipe>
        <div class="iconfont icon-shangyiyehoutuifanhui-xianxingyuankuang return" @click="clickOrderList"></div>
        <div class="gift">
<!--          <div class="giftTxt iconfont icon-servicezengyunfeixian"></div>-->
            <img src='../images/zengicion.png' style="width: 0.93rem;height: 0.93rem">
          <div class="giftMoreTxt">
            <h3>赠送七天租赁时长</h3>
            <h4>下单必赠，无忧租赁</h4>
          </div>
        </div>
      </div>
     <div style="padding: 0.21rem">
       <div class="namePrice">
         <div class="price" v-if="productData.name!='测绘玩家A10'">
           <span>￥</span>
           <span>{{ productData.showPrice }}</span>
           <span>/天起</span>
           <span style="margin-left: 0.09rem">￥120</span>
         </div>
         <div class="price" v-else>
           <span>￥</span>
           <span>{{ productData.showPrice }}</span>
           <span>/天</span>
           <span style="margin-left: 0.09rem">￥120</span>
         </div>

         <h3>{{ productData.name }}</h3>
         <div class="usServe">
           <div class="usServeContent">
             <img src="../images/qualityGuarantee.png">
             <div>质保省心</div>
           </div>
           <div class="usServeContent">
             <img src="../images/dedicatedCustomerService.png">
             <div>专属客服</div>
           </div>
           <div class="usServeContent">
             <img src="../images/fastDelivery.png">
             <div>极速发货</div>
           </div>
         </div>
       </div>

       <!--      租赁单价-->
       <div class="unitPrice">
         <div class="unitPriceTitle" v-if="productData.name!='测绘玩家A10'">
           租赁单价<span>7天起租，未满7天按7天计算</span>
         </div>
         <div class="unitPriceTitle" v-else>
           租赁单价<span>361天起租</span>
         </div>
         <div class="unitPriceDetails" v-for="(v,index) in priceMap['0'] " :key="index">
           <div>{{ v.fate }}</div>
           <div>
             ￥{{ v.unitPrice }}/天
           </div>
         </div>
       </div>
       <!--      配件清单-->
       <div class="accessoriesList">
         <div>配件清单</div>
         <div>{{ accessoriesName }}</div>
         <div @click="lookAtAll"> <span class="iconfont icon-xiayiyeqianjinchakangengduo"></span></div>
       </div>
       <!--      租赁流程-->
       <div class="leaseProcess">
         <h2>租赁流程</h2>
         <div class="aboutLease">
           <h3><span class="iconfont icon-gengxinrizhi"></span>租期计算</h3>
           <p>例如您选择的是租期是<span>2号-8号</span>（计费时间为此<span>7天</span>）,来回物流不算租期，寄回时间以快递员揽收时间为准。</p>
         </div>
         <div class="aboutLease">
           <h3><span class="iconfont icon-lishibisai"></span>租期计算</h3>
           <p>查看订单-续租-支付租金-继续使用</p>
         </div>
         <div class="aboutReturn">
           <div>
             <h3><span class="iconfont icon-guihuan1"></span>归还</h3>
           </div>
           <p>查看订单 - 退租 - 寄回设备 - （到期次日寄回）- 商家签收并 质检设备 - 完结订单</p>
         </div>
       </div>
       <!--      用户口碑-->
       <div class="users">
         <div class="userHeader">
           <div>用户口碑</div>
           <div @click="toViewComments">
             查看全部
             <span class="iconfont icon-xiayiyeqianjinchakangengduo" style="font-size: 0.3rem"></span>
           </div>
         </div>
         <div class="userContent"  v-model="evaluates"  v-if="evaluates.length>0">
           <div class="userContentLeft">
             <img :src="evaluates[0].headPic"/>
           </div>
           <div class="userContentRight">
             <div class="userContentTime">
               <div>
                 {{ evaluates[0].userPhone.substring(0,3)+"*****"+evaluates[0].userPhone.substring(8) }}
               </div>
               <div>
                 {{ evaluates[0].createTime |dataFormat}}
               </div>
             </div>
             <div class="leaseTime">租期：{{ evaluates[0].startRentDate |dataFormat }}-{{evaluates[0].expireDate |dataFormat}}</div>
             <p>
               {{ evaluates[0].evaluate }}
             </p>
           </div>
         </div>
         <div class="orderBackground" v-show="evaluates.length==0">
           <p>暂无评价</p>
         </div>
       </div>
       <!--      选项卡-->
       <div class="tab">
         <van-tabs v-model="active" animated color="#FB884F" title-active-color="#FB884F">
           <van-tab title="设备详情" class="equipmentDetails"  style="padding-bottom: 100px">
             <img v-for="(footerPic,index) in footerPics" :src="footerPic" :key="index"/>
           </van-tab>
           <van-tab title="常见问题" class="commonProblem">
             <div class="problemList" style="padding-bottom: 100px">
               <div class="specificProblems">
                 <div class="problemTitle">
                   <div>Q</div>
                   <div>未到租赁结束日可以提前寄回产品吗？</div>
                 </div>
                 <p>
                   a).订单发货后不支持非产品原因拒签或租期开始前
                   寄回产品，您将承担来回运费并按照原定租赁的租
                   金结算 ;
                 </p>
                 <p>
                   b).如您单方面违约，将产品转租、转寄、转售或更
                   改收件人信息或长时间未签收设备，我司有权召回
                   快递并由您承担来回运费及1个月的租金;
                 </p>
               </div>
               <div class="specificProblems">
                 <div class="problemTitle">
                   <div>Q</div>
                   <div>归还产品？</div>
                 </div>
                 <p>
                   租期结束，(1) 快递产品寄回:根据订单中提示的寄
                   回地址，选用顺丰寄付的方式将产品寄回。我司确
                   认收货后3个工作日内完成产品检测及押金退还；
                 </p>
               </div>
               <div class="specificProblems">
                 <div class="problemTitle">
                   <div>Q</div>
                   <div>预授押金怎么退?？</div>
                 </div>
                 <p>
                   在收到您寄回的租赁产品后，我司将安排工作人员
                   进行产品清洗和检测，在确认产品没有损坏的情况
                   下，押金将于检测完毕的2个工作日内退回到原支付
                   账户中。
                 </p>
               </div>
               <div class="specificProblems">
                 <div class="problemTitle">
                   <div>Q</div>
                   <div>产品的定损和赔偿标准？</div>
                 </div>
                 <p>
                   1、定损主体:
                 </p>
                 <p>
                   (1) 产品主体:有损毁、无维修价值、 硬件维修、软
                   件维修、外观磨损五种结果;
                   (2) 产品配件:有损毁、无维修价值、外观磨损三种
                   结果;
                   (3) 以上定损结果，均需用户按照赔偿标准进行赔偿；
                 </p>
               </div>
               <div class="specificProblems">
                 <div class="problemTitle">
                   <div>Q</div>
                   <div>收货体验？</div>
                 </div>
                 <p>
                   下单成功后，产品将通过顺丰快递发货，需用户本
                   人签收使用。
                 </p>
                 <p>
                   b).如您单方面违约，将产品转租、转寄、转售或更
                   改收件人信息或长时间未签收设备，我司有权召回
                   快递并由您承担来回运费及1个月的租金;
                 </p>
               </div>
               <div class="specificProblems">
                 <div class="problemTitle">
                   <div>Q</div>
                   <div>收货体验？</div>
                 </div>
                 <p>
                   下单成功后，产品将通过顺丰快递发货，需用户本
                   人签收使用。
                 </p>
                 <p>
                   b).如您单方面违约，将产品转租、转寄、转售或更
                   改收件人信息或长时间未签收设备，我司有权召回
                   快递并由您承担来回运费及1个月的租金;
                 </p>
               </div>
             </div>
           </van-tab>
         </van-tabs>
       </div>
     </div>
    </div>
    <!--    底部购物导航-->
    <div class="goodsAction">
      <div class="goodsHome" @click="goIndex">
        <div>
          <img src='../images/menuIcon.png'>
        </div>
        <div style="margin-top: -0.2rem">首页</div>
      </div>
      <div class="goodsCustomer" @click="showKf">
        <div>
          <img src='../images/serviceIcon.png'>
        </div>
        <div style="margin-top: -0.2rem">客服</div>
      </div>
      <div class="orderImmediately" @click="clickDetails">
        立即下单
      </div>
    </div>
    <!--    购物详情-->
    <div class="addShoppingCart" v-show="show">
      <div class="aboutShopping">
        <van-icon name="close" class="conceal" @click="closed"/>
        <!--          产品-->
        <div class="aboutPanelHeader">
          <div class="panelImg">
            <img :src="imgList[0]"/>
          </div>
          <div class="panelPrice">
            <h3>{{ productData.name }}</h3>
            <div class="aboutPanelPrice">
              <span>￥</span>
              <span>{{ unitPrice }}</span>
              <span>/天起</span>
            </div>
            <p>请选择租期</p>
          </div>
        </div>
        <!--          租赁日期-->
        <div class="dateOfService">
          <div class="timeText">预租日期</div>
          <div class="changeTime">
            <div :value="endDate" @click="timeShow = true" class="endTime">{{ endDate }}</div>
            <span style="font-size: 0.6rem;color: #999;" class="iconfont icon-rili"></span>
            <van-calendar v-model="timeShow" :min-date="selectStartTime"
                          @confirm="onConfirm" color="#FB884F"/>
          </div>
        </div>
        <!--          租赁天数-->
        <div class="numberOfDaysOfLease">
          <div>
            租赁天数
          </div>
          <div>
            <van-stepper v-if="productData.name!='测绘玩家A10'" v-model="value" min="7" input-width="0.86rem" button-size="0.62rem"/>
            <van-stepper v-else v-model="value" min="361" input-width="0.86rem" button-size="0.62rem"/>
          </div>
        </div>
        <!--          租赁数量-->
        <div class="numberOfDaysOfLease">
          <div>
            租赁数量
          </div>
          <div>
            <van-stepper v-model="valueNum" input-width="0.86rem" button-size="0.62rem" disabled/>
          </div>
        </div>
        <!--          租期-->
        <div class="tenancyTerm">
          <div>租期</div>
            <div class="tenancyTermTime" style="font-weight: normal">
              <div>
                {{ newTime }}-{{ endDate }}，
              </div>
              <div>包含商家赠送7天</div>
            </div>
        </div>
        <!--          租金-->
        <div class="rent">
          <div>租金</div>
          <div style="font-weight: normal">￥{{ total }}</div>
        </div>
        <!--          确认-->
        <div class="ok" @click="closed1">确认</div>
      </div>
    </div>
    <!--    配件清单-->
    <van-popup v-model="partsListShow" round position="bottom" class="partsList" :style="{ height: '65%'}">
      <div class="partsListHeader">
        <div class="partsListHeaderTxt">配件清单 ({{ pmsAccessoriesCount }})</div>
        <van-icon name="close" class="partsListHeaderIcon" color="#999999" size="0.61rem"
                  @click="partsListHeaderIconReturn"
        />
      </div>
      <div class="guding">
        <div class="parts" v-for="(v,index) in productData.pmsAccessoriesList" :key="index">
          <div>
            <img :src="v.picture"/>
          </div>
          <div class="partsTxt">
            <h3>{{ v.name }}</h3>
            <h4>数量：{{ v.number }}</h4>
            <h5>价值：￥{{ v.price }}</h5>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import leaseApi from "../../utils/leaseApi";
import {Notify} from "vant";
import TEMPURL from "../../utils/tempurl";
import { Dialog } from 'vant';
import { Toast } from 'vant';
export default {
  name: "product",
  // computed : {
  //   endDate : {
  //     get(){
  //       return this.date
  //     },
  //     set(val){
  //       console.log(val)
  //
  //     }
  //   },
  // },
  watch: {
    endDate(newVal, oldVal) {
      let set = new Set();
      let priceMap = {}
      this.priceMap['0'].map(p => {
        let key = p.fate.substring(1, p.fate.length - 1).split("-").map(Number)
        set.add(key)
        priceMap[key] = p.unitPrice
      })
      let startTime = this.convertDate(this.newTime, 7);
      let dayDiffDay = this.DateDiff(startTime, newVal)
      set.forEach(d => {
        if (d[0] <= dayDiffDay && dayDiffDay <= d[1]) {
          this.total = parseInt(priceMap[d] * dayDiffDay)
          this.unitPrice = priceMap[d]
          this.value = dayDiffDay
          return;
        }
      })
    },
    value(newVal, oldVal) {
      if (newVal < 7) {
        newVal = 7
        Notify({type: 'danger', message: '最小租期不能小于7天'});
      }
      if (newVal === oldVal) {
        return;
      }
      this.endDate = this.convertDate(this.newTime, newVal + 7);
    }
  },
  data() {
    return {
      unitPrice: 0.0,
      //结束时间
      endDate: '预计结束时间',
      current: 0,
      // 选项卡切换
      active: 0,
      // 蒙版层
      show: false,
      date: '',
      // 时间选择展示
      timeShow: false,
      // 配件清单
      partsListShow: false,
      newTime: "",
      selectStartTime: "",
      total: 0,
      value: 7,
      // 输入天数
      valueNum: 1,
      productData: {},
      priceMap: {},
      accessoriesName: "",
      pmsAccessoriesCount: 0,
      startMin: '2021-12-01',
      endMax: '2022-01-01',
      imgList:[],
      price:"",
      footerPics: [],
      evaluates:{},
      headPortrait:[]
    };
  },
  methods: {
    goIndex(){
      this.$router.push({path:"/lease"})
    },
    showKf(){
      Dialog.alert({
        message: '<p style="font-size: 0.53rem;font-weight: bold">19150324669</p>',
        showConfirmButton:true,
        allowHtml:true,
        showCancelButton:true,
        confirmButtonText:'确认',
        cancelButtonText:'取消',
        confirmButtonColor:'#3366FF',
        cancelButtonColor:'#3366FF',
      }).then(() => {
          window.location.href='tel://'+'19150324669'
        // on close
      }).catch(()=>{

      });


    },
    // 选项卡
    onChange(index) {
      this.current = index;
    },
    // 展示详情
    clickDetails() {
      this.show = true
    },
    // 选择时间日期
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    // 获取当前时间
    getNewTime() {
      let nowDate = new Date()
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      }
      let nowDateStr = date.year + '/' + date.month + '/' + date.date
      this.newTime = nowDateStr;
      this.selectStartTime = new Date(this.convertDate(nowDateStr, 14).replace('-', '/'));
    },
    onConfirm(date) {
      this.timeShow = false;
      this.endDate = this.formatDate(date);
    },
    // 关闭购物
    closed() {
      this.show = false
    },
    closed1() {
      this.show = false

      //详情页跳转传参
      // console.log(this.imgList[0]);
      // console.log(this.priceMap[2][0]);
      let data={name:this.productData.name,img:this.imgList[0],unitPrice:this.unitPrice,serviceCharge:this.priceMap[2][0],endDate:this.endDate, value:this.value,newTime:this.newTime,valueNum:this.valueNum,total:this.total,guarantee:this.priceMap[1][0]}
      this.$store.commit('setParticulars',data)
      this.$store.commit('setOrderData',{commodityId : this.productData.id , rentDay : this.value})
      // this.$router.push({name: 'myOrderDetails',params:data})
      this.$router.push({
          name: 'myOrderDetails',
          })
    },
    // 查看配件清单
    lookAtAll() {
      this.partsListShow = true
    },
    partsListHeaderIconReturn() {
      this.partsListShow = false
    },
    // 返回订单列表
    clickOrderList() {
      this.$router.push({name: "classify"})
    },
    // 查看全部评论
    toViewComments(){
      this.$router.push({name: "allViewComments",params:{'evaluates':this.evaluates}})
    },
    //加载数据
    async fetchData() {
      // console.log(this.evaluates)
      let productId = this.$route.query.productId
      //商品详情图片
      const {data} = await leaseApi.getProductDetails({productId: productId});
      // console.log(data)

          //图片加前缀
      data.headPics.forEach(e=>{
        this.imgList.push(TEMPURL + e)
      })
      data.footerPics.forEach(e=>{
        //底部图片
        this.footerPics.push(TEMPURL + e)
      })
      data.productData.pmsAccessoriesList.forEach(e=>{
        //评论头像
        e.picture = TEMPURL + e.picture
      })
      //商品评论
      data.productData.evaluates.forEach(e=>{
        // let x = e.userName.substring(0, 1)
        let x = e.userName.substring(0, e.userName.length-1)
        // let l = ""
        // for (let i = 0; i < e.userName.length -1; i++) {
        //   l = l+ "*"
        // }
        // console.log(l)
        e.userName = x+"*"
      })

      this.evaluates = data.productData.evaluates


      //所有商品详情
      this.productData = data.productData;
      this.pmsAccessoriesCount = data.productData.pmsAccessoriesList.length
      this.priceMap = data.priceMap;
      let str = "";
      data.accessoriesList.forEach(a => {
        str += a + "·"
      })
      // console.log(this.priceMap['0'])

      let priceMap = {};
      let dayArray = this.priceMap['0'].map(p =>{
        priceMap[p.fate] = p.unitPrice
        return p.fate
      })

      dayArray.forEach(d =>{
        let a = d.substring(1,d.length - 1)
        let split = a.split("-");
        if (parseInt(split[0]) === 7 ){
          this.unitPrice = priceMap[d]
          this.total = priceMap[d] * 7
        }
      })
      this.endDate = this.convertDate(this.newTime, 14);
      // console.log(this.endDate)
      // console.log(this.total)

      // this.unitPrice = Math.max.apply(Math, this.priceMap['0'].map(p => {
      //   return p.unitPrice
      // }))

      this.accessoriesName = str;

      // this.total =this.unitPrice*7
    },
    /**
     * @param  {string} date 起始日期
     * @param  {number} day 向后的天数
     * @return {string} 返回想要得到的日期
     */
    convertDate(date, day) {
      let tempDate = new Date(date);
      tempDate.setDate(tempDate.getDate() + day);
      let Y = tempDate.getFullYear();
      let M = tempDate.getMonth() + 1 < 10 ? '0' + (tempDate.getMonth() + 1) : tempDate.getMonth() + 1;
      let D = tempDate.getDate() < 10 ? '0' + (tempDate.getDate()) : tempDate.getDate();
      return (Y + "/" + M + "/" + D);
    },
    DateDiff(sDate1, sDate2) { //sDate1和sDate2是2017-9-25格式
      var aDate, oDate1, oDate2, iDays
      aDate = sDate1.split("-")
      oDate1 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
      aDate = sDate2.split("-")
      oDate2 = new Date(aDate[1] + '-' + aDate[2] + '-' + aDate[0])
      iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24)
      return iDays
    },
  },
  created() {
    this.fetchData()
    this.getNewTime()

  }
}
</script>

<style lang="less" scoped>
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 0.5rem;
  font-size: 12px;
  width: 1.44rem;
  height: 0.58rem;
  border-radius: 0.29rem;
  color: #FFFFFF;
  font-size: 0.33rem;
  /*font-family: 'xiaocheng';*/
  font-weight: 400;
  text-align: center;
  line-height: 0.58rem;
  background: rgba(0, 0, 0, 0.16);
}

.productPag {
  height: 100%;
  background: #F6F6F6;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;

    .headBanner {
      position: relative;

      .banner {
        img {
          width: 100%;
        }
      }

      .return {
        position: absolute;
        top: 0.19rem;
        left: 0.42rem;
        font-size: 0.61rem;
        color: #EFEFEF;
      }

      .gift {
        height: 1.22rem;
        background: linear-gradient(86deg, #0098F0 0%, #3BB6EE 45%, #FEF5F0 100%);
        display: flex;
        padding-left: 0.53rem;
        align-items: center;
        //margin-top: -0.1rem;
        .giftTxt {
          color: #FFFFFF;
          font-size: 0.86rem;
          align-self: center;
          margin-right: 0.22rem;
        }

        .giftMoreTxt {
          align-self: center;
          margin-left: 0.2rem;
          h3 {
            font-size: 0.42rem;
            /*font-family: 'xiaocheng';*/

            font-weight: bold;
            color: #FFFFFF;
          }

          h4 {
            font-size: 0.31rem;
            /*font-family: 'xiaocheng';*/

            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }


    }
    .namePrice {
      padding-left: 0.49rem;
      background: #FFFFFF;
      padding-top: 0.14rem;
      padding-bottom: 0.44rem;
      border-radius: 0.21rem;

      h3 {
        font-size: 0.44rem;
        /*font-family: 'xiaocheng';*/

        font-weight: bold;
        color: #333333;
      }

      .price {
        span:nth-child(1) {
          font-size: 0.39rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 400;
          color: #FB884F;
        }

        span:nth-child(2) {
          font-size: 0.67rem;
          /*font-family: 'xiaocheng';*/

          font-weight: bold;
          color: #FB884F;
          margin-left: -0.1rem;
        }

        span:nth-child(3) {
          font-size: 0.42rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 400;
          color: #FB884F;
        }

        span:nth-child(4) {
          font-size: 0.33rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 400;
          text-decoration: line-through;
          color: #999999;
        }
      }
    }
    .unitPrice {
      background: #FFFFFF;
      padding: 0.43rem 0.44rem 0.04rem 0.44rem;
      border-radius: 0.28rem;
      margin-top: 0.21rem;

      .unitPriceTitle {
        font-size: 0.42rem;
        /*font-family: 'xiaocheng';*/

        font-weight: bold;
        color: #333333;
        margin-bottom: 0.42rem;

        span {
          font-size: 0.31rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 400;
          color: #999999;
          margin-left: 0.22rem;
        }
      }

      .unitPriceDetails:nth-child(2) {
        display: flex;
        margin-bottom: 0.47rem;
        align-items: center;
        color: white;
        div:nth-child(1) {
          font-size: 0.36rem;
          /*font-family: 'xiaocheng';*/
          height: 0.8rem;
          background: #FFAF88;
          border-radius: 0.4rem 0rem  0rem 0.4rem;
          text-align: center;
          line-height: 0.8rem;
          font-weight: 400;
          color: #333333;
          width: 2.53rem;
          color: white;
          margin-right: 0.21rem;
        }

        div:nth-child(2) {
          width: 5.33rem;
          height: 0.8rem;
          background: #FFAF88;
          border-radius: 0rem 0.4rem 0.4rem 0rem;
          text-indent: 0.69rem;
          font-size: 0.35rem;
          /*font-family: 'xiaocheng';*/
          line-height: 0.8rem;
        }
      }
      .unitPriceDetails:nth-child(3) {
        display: flex;
        margin-bottom: 0.47rem;
        align-items: center;
        color: white;
        div:nth-child(1) {
          font-size: 0.36rem;
          /*font-family: 'xiaocheng';*/
          height: 0.8rem;
          background: #FF9C6B;
          border-radius: 0.4rem 0rem  0rem 0.4rem;
          text-align: center;
          line-height: 0.8rem;
          font-weight: 400;
          color: #333333;
          width: 2.53rem;
          color: white;
          margin-right: 0.21rem;
        }

        div:nth-child(2) {
          width: 4rem;
          height: 0.8rem;
          background: #FF9C6B;
          border-radius: 0rem 0.4rem 0.4rem 0rem;
          text-indent: 0.69rem;
          font-size: 0.35rem;
          /*font-family: 'xiaocheng';*/
          line-height: 0.8rem;
        }
      }
      .unitPriceDetails:nth-child(4) {
        display: flex;
        margin-bottom: 0.47rem;
        align-items: center;
        color: white;
        div:nth-child(1) {
          font-size: 0.36rem;
          /*font-family: 'xiaocheng';*/
          height: 0.8rem;

          background: #FB884F;
          border-radius: 0.4rem 0rem  0rem 0.4rem;
          text-align: center;
          line-height: 0.8rem;
          font-weight: 400;
          color: #333333;
          width: 2.53rem;
          color: white;
          margin-right: 0.21rem;
        }

        div:nth-child(2) {
          width: 2.93rem;
          height: 0.8rem;
          background: #FB884F;
          border-radius: 0rem 0.4rem 0.4rem 0rem;
          text-indent: 0.69rem;
          font-size: 0.35rem;
          /*font-family: 'xiaocheng';*/
          line-height: 0.8rem;
        }
      }
    }

    .accessoriesList {
      height: 1.28rem;
      background: #FFFFFF;
      border-radius: 0.28rem;
      margin-top: 0.21rem;
      display: flex;
      justify-content: space-between;
      line-height: 1.28rem;
      padding: 0rem 0.23rem 0rem 0.44rem;

      div:nth-child(1) {
        font-size: 0.42rem;
        /*font-family: 'xiaocheng';*/

        font-weight: bold;
        color: #333333;
      }

      div:nth-child(2) {
        width: 5.25rem;
        font-size: 0.33rem;
        /*font-family: 'xiaocheng';*/

        font-weight: 400;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      div:nth-child(3) {
        font-size: 0.33rem;
        /*font-family: 'xiaocheng';*/

        font-weight: 500;
        color: #666666;

        span {
          font-size: 0.3rem;
          color: #666666;
        }
      }
    }

    .leaseProcess {
      background: #FFFFFF;
      margin-top: 0.21rem;
      border-radius: 0.21rem;
      padding: 0.4rem 0.6rem 0.44rem 0.44rem;

      h2 {
        font-size: 0.42rem;
        /*font-family: 'xiaocheng';*/

        font-weight: bold;
        color: #333333;
        margin-bottom: 0.5rem;
      }

      .aboutLease {
        margin-bottom: 0.56rem;

        h3 {
          font-size: 0.39rem;
          /*font-family: 'xiaocheng';*/

          font-weight: bold;
          color: #333333;
          margin-bottom: 0.21rem;

          span {
            font-size: 0.47rem;
            font-weight: bold;
            margin-right: 0.15rem;
          }
        }

        p {
          font-size: 0.36rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 400;
          color: #666666;
          text-align: justify;

          span {
            font-weight: bold;
            color: #333333;
          }
        }
      }

      .aboutReturn {
        div {
          //display: flex;

          img {
            width: 0.39rem;
            height: 0.39rem;
            align-self: center;
          }

          h3 {
            font-size: 0.39rem;
            /*font-family: 'xiaocheng';*/

            font-weight: bold;
            color: #333333;
            margin-bottom: 0.21rem;
            span {
              font-size: 0.39rem;
              font-weight: bold;
              margin-right: 0.15rem;
            }
          }
        }

        p {
          font-size: 0.36rem;
          /*font-family: 'xiaocheng';*/

          color: #666666;
        }
      }
    }

    .users {
      background: #FFFFFF;
      margin-top: 0.21rem;
      padding: 0.43rem 0.38rem 0.43rem 0.43rem;
      border-radius: 0.21rem;

      .userHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.4rem;

        div:nth-child(1) {
          font-size: 0.42rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 500;
          color: #333333;
          font-weight: bold;
        }

        div:nth-child(2) {
          font-size: 0.33rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 500;
          color: #666666;
          display: flex;
          align-items: center;
        }
      }

      .userContent {
        display: flex;

        .userContentLeft {
          width: 1.11rem;
          height: 1.11rem;
          border-radius: 50%;

          img {
            width: 100%;
            border-radius: 50%;
          }
        }

        .userContentRight {
          flex: 1;
          margin-left: 0.21rem;
          padding-right: 0.6rem;

          .userContentTime {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.08rem;

            div:nth-child(1) {
              font-size: 0.36rem;
              /*font-family: 'xiaocheng';*/

              font-weight: 500;
              color: #333333;
            }

            div:nth-child(2) {
              font-size: 0.33rem;
              /*font-family: 'xiaocheng';*/

              font-weight: 400;
              color: #666666;
            }
          }

          .leaseTime {
            font-size: 0.31rem;
            /*font-family: 'xiaocheng';*/

            font-weight: 400;
            color: #666666;
            margin-bottom: 0.22rem;
          }

          p {
            font-size: 0.39rem;
            /*font-family: 'xiaocheng';*/

            font-weight: 400;
            color: #333333;
            text-align: justify;
          }
        }
      }
    }

    .tab {
      margin-top: 0.21rem;
      .equipmentDetails {
        img {
          width: 100%;
        }
      }

      .commonProblem {
        background: #FFFFFF;

        .problemList {
          padding: 0.49rem 0.97rem 0rem 0.44rem;

          .specificProblems {
            margin-bottom: 0.81rem;

            .problemTitle {
              display: flex;
              margin-bottom: 0.44rem;
              align-items: center;
              div:nth-child(1) {
                width: 0.44rem;
                height: 0.44rem;
                background: #CCCCCC;
                border-radius: 0.08rem;
                font-size: 0.33rem;
                /*font-family: 'xiaocheng';*/

                font-weight: 400;
                color: #FFFFFF;
                text-align: center;
                line-height: 0.44rem;
                margin-right: 0.13rem;
              }

              div:nth-child(2) {
                font-size: 0.39rem;
                /*font-family: 'xiaocheng';*/

                font-weight: bold;
                color: #333333;
              }
            }

            p {
              font-size: 0.39rem;
              /*font-family: 'xiaocheng';*/

              font-weight: 400;
              color: #333333;
              line-height: 0.61rem;
            }
          }
        }
      }
    }
  }

  .goodsAction {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    background: #FFFFFF;
    position: fixed;
    bottom: 1.6rem;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-around;
    justify-content: space-around;
    text-align: center;
    z-index: 1;
    align-items: center;
    height: 1.44rem;
    .goodsHome {
      align-self: center;

      div:nth-child(1) {
        img{
          width: 0.59rem;
          height: 0.59rem;
        }
      }

      div:nth-child(2) {
        font-size: 0.28rem;
        /*font-family: 'xiaocheng';*/

        font-weight: 400;
        color: #666666;
      }
    }

    .goodsCustomer {
      align-self: center;

      div:nth-child(1) {
        img{
          width: 0.59rem;
          height: 0.59rem;
        }
      }

      div:nth-child(2) {
        font-size: 0.28rem;
        /*font-family: 'xiaocheng';*/

        font-weight: 400;
        color: #666666;
      }
    }

    .orderImmediately {
      width: 6rem;
      height: 1.11rem;
      background: linear-gradient(90deg, #FEC90C 0%, #FB884F 100%);
      border-radius: 1rem;
      font-size: 0.44rem;
      /*font-family: 'xiaocheng';*/

      font-weight: 400;
      color: #FFFFFF;
      line-height: 1.11rem;
    }
  }

  .addShoppingCart {
    position: fixed;
    z-index: 9999;
    height: 100%;
    //background: #BBBBBB;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .aboutShopping {
      position: absolute;
      bottom: 0rem;
      left: 0;
      right: 0;
      background: #FFFFFF;
      padding: 1.11rem 0.25rem 0.42rem 0.33rem;
      border-top-left-radius: 0.28rem;
      border-top-right-radius: 0.28rem;

      .conceal {
        position: absolute;
        right: 0.32rem;
        top: 0.43rem;
        color: #666;
        font-size: 0.8rem;
      }

      .aboutPanelHeader {
        display: flex;

        .panelImg {
          width: 2.61rem;
          height: 2.61rem;
          margin-right: 0.49rem;
          border-radius: 0.28rem;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .panelPrice {
          h3 {
            font-size: 0.44rem;
            /*font-family: 'xiaocheng';*/

            font-weight: bold;
            color: #333333;
          }

          .aboutPanelPrice {
            span:nth-child(1) {
              font-size: 0.39rem;
              /*font-family: 'xiaocheng';*/

              font-weight: 400;
              color: #FB884F;
            }

            span:nth-child(2) {
              font-size: 0.67rem;
              /*font-family: 'xiaocheng';*/

              font-weight: 500;
              color: #FB884F;

            }

            span:nth-child(3) {
              font-size: 0.42rem;
              /*font-family: 'xiaocheng';*/

              font-weight: 400;
              color: #FB884F;
            }
          }
        }

        p {
          font-size: 0.33rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 400;
          color: #999999;
        }
      }

      .dateOfService {
        display: flex;
        justify-content: space-between;
        margin-top: 0.4rem;
        .timeText {
          font-size: 0.42rem;
          /*font-family: 'xiaocheng';*/

          font-weight: bold;
          color: #333333;
        }

        .changeTime {
          display: flex;
          align-items: center;
          .endTime {
            font-size: 0.39rem;
            /*font-family: 'xiaocheng';*/

            font-weight: 400;
            color: #333333;
          }
        }
      }

      .numberOfDaysOfLease {
        display: flex;
        justify-content: space-between;
        margin-top: 0.76rem;

        div:nth-child(1) {
          font-size: 0.42rem;
          /*font-family: 'xiaocheng';*/

          font-weight: bold;
          color: #333333;
          align-self: center;
        }

        div:nth-child(2) {
        }
      }

      .tenancyTerm {
        display: flex;
        margin-top: 0.96rem;
        justify-content: space-between;
        font-weight: bold;
        div:nth-child(1) {
          font-size: 0.42rem;
          /*font-family: 'xiaocheng';*/
        }
        .tenancyTermTime{
          display: flex;

          div:nth-child(1) {
            font-size: 0.39rem;
            /*font-family: 'xiaocheng';*/

            align-self: end;
          }

          div:nth-child(2) {
            font-size: 0.39rem;
            /*font-family: 'xiaocheng';*/

            align-self: end;
          }
        }

      }

      .rent {
        margin-top: 0.33rem;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        div:nth-child(1) {
          font-size: 0.42rem;
          /*font-family: 'xiaocheng';*/

        }

        div:nth-child(2) {
          font-size: 0.39rem;
          /*font-family: 'xiaocheng';*/

          align-self: end;
        }
      }

      .ok {
        height: 1.11rem;
        background: #FB884F;
        box-shadow: 0rem 0rem 0rem 0rem rgba(251, 140, 82, 0.13);
        border-radius: 1rem;
        font-size: 0.42rem;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
        line-height: 1.11rem;
        margin-top: 3.25rem;
      }
    }
  }

  .partsList {
    background: #F6F6F6;

    .partsListHeader {
      height: 1.47rem;
      background: #FFFFFF;
      display: flex;
      justify-content: space-between;
      line-height: 1.47rem;
      padding: 0rem 0.5rem;
      margin-bottom: 0.35rem;
      position: fixed;
      //top: 5.9rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      width: 9rem;
      z-index: 99999;
      .partsListHeaderTxt {
        font-size: 0.5rem;
        /*font-family: 'xiaocheng';*/

        font-weight: 500;
        color: #312F2F;
      }

      .partsListHeaderIcon {
        font-size: 0.61rem;
        align-self: center;
      }
    }

    .parts {
      display: flex;
      width: 9.53rem;
      background: #FFFFFF;
      border-radius: 0.28rem;
      margin-left: 50%;
      transform: translateX(-50%);
      margin-bottom: 0.22rem;
      overflow: hidden;
      img {
        width: 3.33rem;
        height: 3.33rem;
        margin-right: 0.68rem;
        vertical-align: middle;
      }

      .partsTxt {
        margin-top: 0.39rem;

        h3 {
          font-size: 0.44rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 400;
          color: #333333;
          margin-bottom: 0.33rem;
        }

        h4, h5 {
          font-size: 0.33rem;
          /*font-family: 'xiaocheng';*/

          font-weight: 400;
          color: #666666;
        }
      }
    }
  }

}
.van-popup--bottom{
  /deep/.van-popup--round {
    border-radius: 0.28rem 0.28rem 0 0 !important;
  }
}

.orderBackground{
  text-align: center;
  img{
    width: 6.94rem;
  }
  p{
    font-size: 0.36rem;
    /*font-family: 'xiaocheng';*/

    font-weight: 400;
    color: #999;
    margin-top: 0.56rem;
  }
}
/deep/.van-stepper__input{
  font-weight: normal;
}
.guding{
  height: 12.6rem;
  padding-top: 1.7rem;
}
/deep/.van-swipe{
  height: 9.2rem;
}
.usServe{
  display: flex;
  justify-content: space-between;
  padding-right: 0.33rem;
  margin-top: 0.4rem;
  .usServeContent{
    display: flex;
    align-items: center;
    img{
      width: 0.45rem;
      height: 0.45rem;
      margin-right: 0.08rem;
    }
    div{
      font-size: 0.29rem;
      /*font-family: 'xiaocheng';*/
      font-weight: 400;
      color: #AAAAAA;
    }
  }
}
/deep/.van-tabs__line{
  width: 0.64rem !important;
  border-top-left-radius: 0.28rem !important;
  border-top-right-radius: 0.28rem !important;
}
</style>
